@import '~bootstrap/scss/bootstrap';
@import 'Utils/math';
@import 'Utils/unit';
@import "Utils/variables";
@import "Utils/override";
@import "Utils/customClass";

// Base
@import "Base/normalize";
@import "Base/global";
@import "Base/fonts";

// Layout
@import "Layout/layout";
@import "Layout/auth";
@import "Layout/public";

//ComponentsOld
@import "Components/icons";
@import "Components/loader";
@import "Components/forms";
@import "Components/hero";
@import "Components/avatar";
@import "Components/skeleton";
@import "Components/breadcrumb";
@import "Components/offCanvas";
@import "Components/menuAction";
@import "Components/buttons";
@import "Components/card";
@import "Components/clientSelecter";
@import "Components/tables";
@import "Components/labels";
@import "Components/tags";
@import "Components/flashMessage";
@import "Components/autoComplete";
@import "Components/formBuilder";
@import "Components/badge";
@import "Components/pricing-departments";
@import "Components/datePicker";

//Pages
@import "Pages/notFoundPage";

