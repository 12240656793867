.skeleton {

  &-input {
    display: flex;
    align-items: center;

    & .left-col {
      margin-right: rem(6);
    }

    & .right-col {
      flex: 1;
    }
  }
}