.menu-action {
  background-color: $color-white;
  border-radius: $border-radius-main;
  border: $border-main;
  position: absolute;
  min-width: rem(200);
  box-shadow: $box-shadow-main;
  z-index: 1000;

  &-avatar {
    right: rem(10);
    top: rem(45);
  }

  &-table {
    min-width: rem(100);
  }

  &__container {
    padding: rem(10) rem(4);
  }

  &-items {
    .level-2 {
      &::before {
        content: '';
        display: block;
        height: rem(1);
        width: 100%;
        background-color: $color-border;
        margin: rem(5) 0;
      }

      &::after {
        content: '';
        display: block;
        height: rem(1);
        width: 100%;
        background-color: $color-border;
        margin: rem(5) 0;
      }

    }
  }

  &-info {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    font-size: rem(12);
    font-weight: 400;
    padding: 0 rem(12);
    color: $color-grey;
  }

  &-item {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    font-size: rem(12);
    font-weight: 500;
    height: rem(28);
    padding: 0 rem(12);

    &:hover {
      cursor: pointer;
      background-color: $color-grey-hover;
      border-radius: $border-radius-main;
    }

    & > svg {
      margin-right: rem(5);
      display: flex;
      align-items: center;
    }
  }

  &-footer {
    display: flex;
    flex-wrap: nowrap;
    right: 0;
    bottom: rem(45);
    width: rem(240);
  }
}
