// TODO : ADD VAR

.not-found-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100vh;
    text-align: center;
    padding: 50px;
    font-family: 'Arial', sans-serif;

    h1 {
        font-size: 3em;
        margin: 0;
        color: $color-orange;
        animation: bounce 1s ease infinite;
    }

    p {
        font-size: 1.5em;
    }

    a {
        margin-top: 20px;
        display: inline-block;
        text-decoration: none;
        color: $color-blue;
        font-size: 1.2em;

        &:hover {
            text-decoration: underline;
        }
    }
}

@keyframes bounce {
    0%, 20%, 50%, 80%, 100% {
        transform: translateY(0);
    }
    40% {
        transform: translateY(-30px);
    }
    60% {
        transform: translateY(-15px);
    }
}