/**
====================================   FONT   ====================================
**/

// Font Size
.font-10 {
  font-size: rem(10);
}

.font-12 {
  font-size: rem(12);
}

.font-14 {
  font-size: rem(14);
}

.font-20 {
  font-size: rem(20);
}

// Font weight
.font-weigth-400 {
  font-weight: 400;
}

.font-weigth-500 {
   font-weight: 500;
 }

.font-weigth-600 {
  font-weight: 600;
}

/**
====================================   HEIGHT / WIDTH   ====================================
**/

.height-50 {
  height: rem(50);
}

.width-height-25 {
  width: rem(25);
  height: rem(25);
}

/**
====================================   SPACING   ====================================
**/

// PADDING
.p-25 {
  padding: rem(25);
}

.pr-25 {
  padding-right: rem(25);
}

.pl-25 {
  padding-left: rem(25);
}

.py-25 {
  padding-top: rem(25);
  padding-bottom: rem(25);
}

.px-25 {
  padding-left: rem(25);
  padding-right: rem(25);
}

.px-50 {
  padding-left: rem(50);
  padding-right: rem(50);
}

/**
====================================   TITLE   ====================================
**/

// Hn
.h1-like {
  font-size: rem(42);
  font-weight: 700;
  line-height: rem(40);
}

.h3-like {
  font-size: rem(24);
  font-weight: 600;
  line-height: rem(31);
}

.h4-like {
  font-size: rem(20);
  font-weight: 600;
  line-height: rem(26);
}

// subtitle
.subtitle {
  font-size: rem(18);
  font-weight: 600;
  line-height: rem(24);
}

/**
====================================   BACKGROUND   ====================================
**/
.bg-white {
  background-color: $color-white;
}

.bg-neutral-quietest {
  background-color: $neutral-quietest;
}

.bg-neutral-lightGray2 {
  background-color: $neutral-lightGray2;

  &-hover:hover {
    background-color: $neutral-lightGray2;
  }
}

.bg-neutral-black {
  background-color: $neutral-black;
}

.bg-lightgreen {
  background-color: lightgreen;
}

/**
====================================   COLORS   ====================================
**/
.color-main {
  color: $color-font;
}

.color-grey {
  color: $neutral-quieter;
}

/**
====================================   BORDER   ====================================
**/
// border-radius
.border-radius-main {
  border-radius: $border-radius-main;
}

.border-right {
  border-right: rem(1) solid black;
}


// width
.min-width-550 {
  min-width: rem(550);
}

/**
====================================   CURSOR   ====================================
**/

div.cursor-normal {
  cursor: initial;
}

div.cursor-pointer {
  cursor: pointer;
}