.autocomplete-search-results {
  position: absolute;
  z-index: 200;
  list-style: none;
  padding: 0;
  border: rem(1) solid #ccc;
  border-radius: rem(4);
  background: white;
  box-shadow: 0 rem(2) rem(4) rgba(0, 0, 0, 0.1);
  max-height: rem(200);
  max-width: rem(350);
  width: 100%;
  overflow-y: auto;
  transform: translateY(rem(50));

  .autocomplete-search-item {
    padding: rem(10) rem(15);
    border-bottom: rem(1) solid #eee;
    cursor: pointer;
    transition: background-color 0.3s;

    &:hover {
      background-color: #f8f8f8;
    }

    &:last-child {
      border-bottom: none;
    }
  }
}

.autocomplete-selected-item {
  padding: rem(10) rem(15);
  border: rem(1) solid #ccc;
  border-radius: rem(4);
  background: white;
  box-shadow: 0 rem(2) rem(4) rgba(0, 0, 0, 0.1);
  max-height: rem(200);
  overflow-y: auto;
}