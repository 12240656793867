/*=========================================
   COLORS
=========================================*/
$color-primary: red;
$color-white: #fff;
$color-font: rgb(55, 53, 47);
$color-grey: #6d6e6f;
$color-grey-secondary: rgb(55, 53, 47);
$color-grey-secondary-50: rgba(55, 53, 47, 0.5);
$color-grey-light: #F4F4F4;
$color-placeholder-input: #AAAAAAF0;
$color-table-thead: #EEEEEE;
$color-border: #E6E6E6;
$color-border-input: #EBEBEB;
$color-grey-hover: #1D2939;
$color-orange: #D9720E;
$color-blue: #0177D3;
$color-cyan: #17a2b8;
$color-error: red;
$color-white-background: #FAFAFA;
$color-green: #9FF494;

/*=========================================
   FONTS
=========================================*/
$font-system : -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans",
"Droid Sans", "Helvetica Neue", sans-serif;

/*=========================================
   GLOBAL
=========================================*/
$main-wrapper: 0 rem(30);
$border-radius-main: rem(6);
$border-radius-medium: rem(3);
$border-main: rem(1) solid $color-border;
$box-shadow-main: 0 rem(2) rem(20) #00000029;
$box-shadow-light: 0 rem(2) rem(10) #00000029;

/*=========================================
   FORM
=========================================*/
// label
$margin-bottom-label: rem(5);

// inputs
$main-border-input: rem(1) solid $color-border-input;
$padding-input: rem(10) rem(12);
$padding-input-formBuilder: rem(5) rem(6);
$margin-bottom-input: rem(12);
$border-radius-input: $border-radius-medium;
$min-width-input: rem(260);
$padding-label: rem(3) 0;

// Example color
$neutral-black: rgb(31, 31, 31);
$neutral-dark: rgb(51, 51, 51);
$neutral-quiet: rgb(102, 102, 102);
$neutral-quieter: rgb(153, 153, 153);
$neutral-quietest: rgb(204, 204, 204);
$neutral-white: rgb(255, 255, 255);
$neutral-lightGray1: rgb(250, 250, 250);
$neutral-lightGray2: rgb(242, 242, 242);
$neutral-lightGray3: rgb(232, 232, 232);
$neutral-lightGray4: rgb(224, 224, 224);
$opacity-darken1: rgba(0, 0, 0, 0.05);
$opacity-darken2: rgba(0, 0, 0, 0.1);
$opacity-darken3: rgba(0, 0, 0, 0.25);
$opacity-darken4: rgba(0, 0, 0, 0.5);
$opacity-lighten1: rgba(255, 255, 255, 0.05);
$opacity-lighten2: rgba(255, 255, 255, 0.1);
$opacity-lighten3: rgba(255, 255, 255, 0.25);
$opacity-lighten4: rgba(255, 255, 255, 0.5);
$blue-blue: rgb(22, 110, 225);
$blue-blueDusty: rgb(59, 102, 163);
$blue-blueDark1: rgb(13, 82, 172);
$blue-blueLight1: rgb(160, 198, 255);
$blue-blueLight2: rgb(209, 226, 255);
$blue-blueLight3: rgb(241, 245, 255);
$cyan-cyan: rgb(57, 202, 255);
$cyan-cyanDusty: rgb(16, 125, 163);
$cyan-cyanDark1: rgb(15, 104, 162);
$cyan-cyanLight1: rgb(136, 219, 255);
$cyan-cyanLight2: rgb(196, 236, 255);
$cyan-cyanLight3: rgb(227, 250, 253);
$teal-teal: rgb(1, 221, 213);
$teal-tealDusty: rgb(13, 127, 120);
$teal-tealDark1: rgb(23, 114, 110);
$teal-tealLight1: rgb(116, 235, 225);
$teal-tealLight2: rgb(193, 245, 240);
$teal-tealLight3: rgb(228, 251, 251);
$green-green: rgb(4, 138, 14);
$green-greenDusty: rgb(64, 124, 74);
$green-greenDark1: rgb(0, 100, 0);
$green-greenLight1: rgb(154, 224, 149);
$green-greenLight2: rgb(207, 245, 209);
$green-greenLight3: rgb(230, 252, 232);
$yellow-yellow: rgb(255, 186, 5);
$yellow-yellowDusty: rgb(162, 104, 17);
$yellow-yellowDark1: rgb(175, 96, 2);
$yellow-yellowLight1: rgb(255, 214, 107);
$yellow-yellowLight2: rgb(255, 234, 182);
$yellow-yellowLight3: rgb(255, 246, 221);
$orange-orange: rgb(213, 68, 1);
$orange-orangeLight2: rgb(250, 193, 167);
$orange-orangeDusty: rgb(148, 77, 55);
$red-red: rgb(187, 16, 16);
$red-redLight1: rgb(189, 41, 41);
$red-redLight2: rgb(255, 176, 176);
$red-redLight3: rgb(255, 234, 234);