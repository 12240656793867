.custom-date-picker {
  border: 1px solid #ccc;
  padding: 4px 8px;
  font-size: 14px;
  border-radius: 6px;
  height: 28px;
  margin-top: 5px;
}

.react-datepicker__tab-loop {
  position: relative;
}

.react-datepicker-popper {
  transform: none !important;
  border-radius: 8px;
  z-index: 500;
}

div.react-datepicker-popper > svg {
  display: none;
}

div.custom-calendar {
  background-color: #f7f7f7;
  border-radius: 8px;
  border: 1px solid #ccc;
}

.weekend {
  color: red;
}

.weekday {
  color: black;
}