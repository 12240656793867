.text {
  &-small {
    font-size: rem(12);
    line-height: rem(18);
    color: $color-grey;
  }

  &-big {
    font-size: rem(44);
    line-height: rem(18);
    color: $color-grey;
  }

  &-center {
    text-align: center;
  }
}

.link {
  &-basic {
    color: inherit;
  }
}