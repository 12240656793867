/**
====================================   GLOBAL   ====================================
**/
.container {
  max-width: rem(1420);
}

/**
====================================   FORM   ====================================
**/
.label {
  font-size: rem(12);
  color: $neutral-quiet;
}

.form-control {
  font-size: inherit;
  font-weight: inherit;
}

.form-select {
  font-size: rem(14);
  margin-bottom: rem(12);
}

/**
====================================   CARD   ====================================
**/
.card {

  &-body {
    padding: 0;
  }
}

/**
====================================   MODAL   ====================================
**/
.modal {
  &-backdrop {
    background-color: transparent;
  }

  &-backdrop.show {
    background-color: transparent;
  }

  &-content {
    border: none;
  }

  &-header {
    border-bottom: none;
  }

  &-body {
    display: flex;
    justify-content: center;
  }
}

/**
====================================   BUTTON   ====================================
**/
.btn {
  border-radius: $border-radius-medium !important;
}

.btn-close:focus {
  box-shadow: none;
}

/**
====================================   TABLE   ====================================
**/
.table > :not(caption) > * > * {
  box-shadow: none;
  border-bottom-width: 0;
}

/**
====================================   OFFCANVAS   ====================================
**/
.offcanvas-sm, .offcanvas-md, .offcanvas-lg, .offcanvas-xl, .offcanvas-xxl, .offcanvas {
  min-width: 50%;
}

.offcanvas {
  &-backdrop {
    background-color: transparent;
  }

  &-backdrop.show {
    background-color: transparent;
  }

  .btn-close {
    height: rem(12);
    width: rem(12);
  }
}

.offcanvas.offcanvas-end {
  border: $border-main;
}

/**
====================================   SWEET ALERT 2   ====================================
**/

div:where(.swal2-container) h2:where(.swal2-title) {
  font-size: rem(20) !important;
  color: $color-font !important;
}

div:where(.swal2-container) button:where(.swal2-styled).swal2-confirm {
  background-color: $color-blue !important;
  font-size: rem(14) !important;
}

div:where(.swal2-container) button:where(.swal2-styled).swal2-cancel {
  background-color: $red-red !important;
  font-size: rem(14) !important;
}

div:where(.swal2-container) .swal2-html-container {
  font-size: rem(14) !important;
}

// COL
.col-2-5 {
  flex: 0 0 auto;
  width: 30%;
}

/**
====================================   DATEPICKER  ====================================
**/

.react-datepicker-wrapper {
  width: 100%;
}