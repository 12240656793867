.offcanvas {
  box-shadow: $box-shadow-main;
  width: 50%;

  &-header {
    padding: rem(25);
  }

  &-container {
    padding: rem(25);
  }

  &-body {
    padding: rem(0);
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    &__container {
      padding: rem(25);
    }
  }

  &-form {
    &-actions {
      position: absolute;
      bottom: 0;
      padding: 25px;
      width: 100%;
      left: 0;
      background-color: $color-grey-light;

      &__container {
        width: 100%;
        display: flex;
        justify-content: flex-end;
      }
    }
  }

  &-nav-row {
    display: flex;
    align-items: center;
    justify-content: center;

    &__btn {
      width: rem(24);
      height: rem(24);
      display: flex;
      align-items: center;
      justify-content: center;

      &:hover {
        background-color: $color-grey-light;
        cursor: pointer;
      }
    }
  }

  .show {
    width: 100%;

    .h2 {
      font-weight: 700;
      color: $color-grey-secondary
    }

    &__container {
      padding: rem(0) rem(25);
    }

    &-header {
      display: flex;
      align-items: center;
      justify-content: space-between;
    }

    &-body {
      margin-top: rem(30);
    }

    &-grid {
      .row {
        height: rem(40);
        display: flex;
        align-items: center;

      }
    }

    &-actions {
      display: flex;
      justify-content: center;
      align-items: center;

      &__btn {
        padding: rem(5) rem(8);
        font-size: rem(12);
        display: flex;
        justify-content: center;
        align-items: center;

        & > svg {
          margin-right: rem(4);
        }

        &:hover {
          background-color: $color-grey-light;
          cursor: pointer;
        }
      }
    }
  }

  &-tabs {
    display: flex;
    position: absolute;
    height: 100%;
    left: rem(-60);
    width: rem(60);
    justify-content: flex-start;
    align-items: flex-start;
    top: rem(20);

    &__tab {
      height: rem(130);
      display: flex;
      width: rem(40);
      background-color: $neutral-lightGray2;
      border: $border-main;
      color: #6d6e6f;
      justify-content: center;
      align-items: center;
      border-radius: rem(6) 0 0 rem(6);
      margin-top: rem(-1);
      font-size: rem(14);
      cursor: pointer;

      &.selected {
        background-color: $color-white;
        color: inherit;
        border-right: none;
        box-shadow: rgba(0, 0, 0, 0.13) -17px 0px 20px;
        width: rem(45);
      }

      & > span {
        height: 40px;
        display: flex;
        width: 130px;
        position: absolute;
        rotate: -90deg;
        justify-content: center;
        align-items: center;
      }
    }

    #tab1 {
      position: absolute;
      z-index: 100;
      background-color: $cyan-cyanLight1;
      color: rgb(0, 99, 143);
      right: 0;
    }

    #tab2 {
      position: absolute;
      top: rem(120);
      z-index: 99;
      background-color: rgb(154, 224, 149);
      color: rgb(8, 83, 2);
      right: 0;
    }

    #tab3 {
      position: absolute;
      top: rem(120*2);
      z-index: 98;
      background-color: rgb(255, 214, 107);
      color: rgb(110, 80, 0);
      right: 0;
    }
  }
}