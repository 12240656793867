.badge {
  background-color: $color-grey-hover;
  display: flex;
  flex-wrap: wrap;
  margin-top: 10px;
  margin-right: 5px;
  padding: 8px 5px;

  &__container {
    display: flex;
    justify-content: space-between;
    color: $color-grey;
    cursor: grab;
  }
}