* {
  -webkit-font-smoothing: antialiased;
  font-synthesis-weight: none;
}

html {
  height: 100vh;
  width: 100vw;
  overflow: hidden;
  -webkit-font-smoothing: antialiased;
}

#publisur-app {
  height: 100vh;
  width: 100%;
  position: relative;
  background-color: transparent;
  overflow: hidden;
}

.publisur__container {
  width: 100%;
  height: 100%;
}

.publisur-app-inner, body {
  height: 100%;
  margin: 0;
  font-family: $font-system;
  font-size: rem(14);
  color: $color-font;
  -webkit-font-smoothing: auto;
  -moz-osx-font-smoothing: grayscale;
}

.h1 {
  font-size: rem(24);
  font-weight: 700;
  line-height: rem(40);
  display: flex;
  align-items: center;
  margin: 0;
  color: $color-grey-secondary;

  .icon {
    margin-right: rem(10);
  }
}

// CUSTOM CLASS

.error-message {
  color: $color-error;
  font-size: rem(12);
  padding: rem(10) 0;
}

.box-shadow {
  box-shadow: $box-shadow-light;
}

.b-radius {
  border-radius: $border-radius;
}

.card-logo {
  height: rem(20);
  width: rem(20);
  background-color: #0177D3;
  margin-right: rem(20);
}

.text-color-light {
  color: $color-grey;
}

.disabled-item {
  opacity: 0.5;
  pointer-events: none;
}

div[data-contents="true"] {
  height: 400px;
}

// SCROLL BAR
* {
  scrollbar-width: thin;
  scrollbar-color: #d4d4d4 #ffffff;
}

.hidden-scrollbar {
  overflow: scroll;
  scrollbar-width: none;
}
