@use 'sass:math';

.layout {
  height: 100%;
  display: flex;
}

.header {
  z-index: 1000;
  top: 0;
  left: 0;
  width: 100%;
  height: rem(50);
  position: fixed;
  background-color: $color-white;
  border-bottom: 0.5px solid #B5B5B572;
  font-weight: 500;

  &__container {
    padding: 0 rem(25);
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
  }

  &-brand {
    height: 100%;
    display: flex;
    align-items: center;
    width: rem(240);
    padding-right: rem(25);
    justify-content: space-between;
  }

  &-main {
    display: flex;
    width: calc(100% - 240px);

    &__container {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: space-between;
    }

    &-client {
      cursor: pointer;

      .current-client {
        &-display {
          display: flex;
          align-items: center;

          .bi-chevron-expand {
            font-size: rem(15);
            margin-left: rem(3);
          }
        }
      }
    }

    &-actions {
      display: flex;
      justify-content: flex-end;
      z-index: 101;
      align-items: center;

      .date {
        margin-right: rem(70);
      }

      .profil {
        height: rem(30);
        width: rem(100%);
        display: flex;
        justify-content: center;
        align-items: center;

        & > svg {
          align-items: center;
          stroke-width: 1;
          margin-left: rem(5);
          margin-top: rem(2);
        }
      }
    }
  }
}

.sidebar {
  z-index: 999;
  width: rem(240);
  height: 100%;
  position: fixed;
  background: #FAFAFA 0% 0% no-repeat padding-box;
  border-right: 0.5px solid #B5B5B572;
  font-weight: 500;

  &__container {
    position: relative;
    width: 100%;
    height: 100%;
    padding-top: rem(50);
    padding-bottom: rem(40);
  }

  &-menu {
    top: rem(80);
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }

  .main-menu {
    margin: rem(20) rem(4) 0 rem(4);

    .menu-item {
      height: rem(30);
      padding: 0 rem(25);
      display: flex;
      align-items: center;

      & a {
        display: flex;
        align-items: center;
      }

      &:hover {
        cursor: pointer;
        background-color: $color-grey-hover;
        border-radius: $border-radius-main;
      }
    }

    &-footer {
      opacity: 1;

      &__container {

      }
    }
  }
}

.main-wrapper {
  padding: $main-wrapper;
  margin-top: rem(10);
}

.outlet {
  z-index: 0;
  height: calc(100vh - 50px);
  width: calc(100% - 240px);
  left: rem(240);
  position: relative;
  top: rem(50);

  &__container {
    height: 100%;
    padding: 0 rem(50);
  }
}

.footer-menu {
  z-index: 110;
  position: absolute;
  height: rem(34);
  width: rem(34);
  border-radius: 50%;
  background: #F9F6FCB3 0% 0% no-repeat padding-box;
  box-shadow: 0px 3px 6px #A782C333;
  bottom: 0;
  right: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: rem(25);
  margin-bottom: rem(25);
  cursor: pointer;
}
