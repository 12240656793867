.formBuilder {

  &-menu {
    width: 100%;
    display: flex;
    margin-top: rem(10);

    &__container {
      padding: 0 rem(35);
      width: 100%;
    }

    &-tabs {
      display: flex;
      height: 100%;
      width: 100%;
      justify-content: flex-start;
      align-items: center;
      padding: $main-wrapper;
    }

    &-tab {
      width: rem(150);
      display: flex;
      cursor: pointer;
      color: $neutral-quietest;

      & > span {
        font-weight: 500;
        font-size: rem(14);
        padding-bottom: rem(10);
      }

      &.selected > span {
        color: $color-font;
        box-shadow: inset 0 -2px black;
        display: block;
        padding-bottom: rem(10);
      }
    }
  }

  &-content {
    width: 100%;
    height: 100%;

    &__container {
      width: 100%;
      padding: rem(30) rem(60);
    }
  }

  &-category-title {
    font-size: rem(16);
    font-weight: 600;
    color: $neutral-quieter;
    border-bottom: rem(1) solid $neutral-quieter;
  }

  &-list {
    width: rem(300);
    margin-top: rem(10);

    &__input {
      width: 100%;
      display: flex;
      align-items: center;

      & .icon {
        margin-right: rem(4);
        width: rem(25);
        height: 100%;
        cursor: pointer;

        &.draggable > svg {
          fill: $color-grey;
          cursor: grabbing;
        }

        & > svg {
          fill: $red-red;
          font-size: rem(18);
        }
      }

      & input {
        background-color: $color-grey-light;
        border-radius: $border-radius-input;
        border: $main-border-input;
        padding: $padding-input-formBuilder;
        width: 100%;
        margin-top: rem(4);
      }

      &.add {
        width: rem(300);
        & .icon > svg {
          fill: #0177D3;
        }
      }
    }
  }

  &-section {

    &__render {
      background-color: $color-font;
      border-radius: $border-radius;
      display: flex;
      justify-content: center;
      align-items: center;
    }

    &-card {
      width: 100%;
      border: $border-main;
      border-radius: $border-radius-medium;
      display: flex;
      justify-content: flex-start;
      align-items: center;
      margin-bottom: rem(8);

      &.closed {
        height: rem(50);
        width: 100%;
      }

      &__container {
        padding: rem(25);
        width: 100%;
      }

      &__title {
        font-size: rem(20);
        font-weight: 700;
      }
    }
  }
}