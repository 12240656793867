.auth {
  height: 100%;
  width: 100%;

  &-header {
    position: fixed;
    height: rem(100);
    width: 100%;
    z-index: 100;
    top: 0;

    &__inner {
      font-size: rem(16);
      font-weight: 500;
      color: rgb(55, 53, 47);
      white-space: nowrap;
      height: 100%;
      width: 100%;
      background: $color-white;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
    }

    &__container {
      display: flex;
      align-items: center;
      justify-content: left;
      width: 100%;
      max-width: rem(1300);
      padding: rem(0) rem(20);
      transition: height 250ms ease 0s;
      height: 100%;
      position: relative;
      overflow: hidden;
      box-shadow: none;
    }
  }

  &-form {
    min-width: rem(320);
    &__container {
    }
  }

  &-content {
    height: 100%;
    width: 100%;

    &__container {
      position: relative;
      z-index: 1;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      width: 100%;
      max-width: rem(1300);
      padding: rem(0) rem(20);
      height: 100%;
      margin: auto;

      .h1 {
        color: rgb(55, 53, 47);
        justify-content: center;
        font-size: rem(42);
        font-weight: 700;
        margin-bottom: rem(30);
      }
    }

    &__actions {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      margin-top: rem(50);
      width: rem(500);
      text-align: center;

      & small {
        color: $color-grey-secondary;
      }

      & > button {
        margin-bottom: rem(8);
      }
    }
  }

  &-footer {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-top: rem(30);

    .forget-password {
      text-decoration: underline;
    }

    &-terms {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      margin-top: rem(30);
      width: rem(500);
      text-align: center;

      & a {
        text-decoration: underline;
      }

      & small {
        color: $color-grey-secondary;
      }
    }
  }

  &-select-client {
    display: flex;
    justify-content: center;
    margin: rem(20) 0;
    flex-wrap: wrap;
    width: rem(500);

    .card {
      border-radius: $border-radius-main;
      margin: rem(10) rem(10);

      &:hover {
        background-color: $color-grey-light;
        transition: 0.25s;
      }
    }
  }
}