.loader {
  position: absolute;
  z-index: 1003;
  background-color: rgba($color-white, 0.8);
  width: 100%;
  height: 100vh;
  top: 0;
  left: 0;
  transition: 500ms;

  &__container {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
  }

  .spinner {
    &-grow {
      height: rem(50);
      width: rem(50);
    }

    &-md {
      height: rem(15);
      width: rem(15);
      position: absolute;
      background-color: $orange-orangeLight2;
    }

    &-sm {
      height: rem(30);
      width: rem(30);
      position: absolute;
      background-color: $orange-orangeLight2;
    }

    &-orange {
      background-color: $orange-orangeLight2;
    }
  }

  &-component {
    position: absolute;
  }
}