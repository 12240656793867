.input {
  background-color: $color-grey-light;
  border-radius: $border-radius-input;
  border: $main-border-input;
  padding: $padding-input;
  margin-bottom: $margin-bottom-input;

  &::placeholder {
    color: $color-placeholder-input;
  }

  &-medium{
    padding: 0.3125rem 0.375rem;
  }

  &-edit {
    padding: rem(4) rem(10);
    margin: 0;
    width: 100%;
  }

  &-submit {
    width: 100%;
    display: flex;
    justify-content: flex-end;
  }

  &-ellipsis {
    overflow: hidden;
    text-overflow: ellipsis;
  }

  &-required {
    color: $red-red;
  }
}


.block {
  &-field {
    display: flex;
    flex-direction: column;
  }
}

.textarea {
  background-color: $color-grey-light;
  border-radius: $border-radius-input;
  border: $main-border-input;
  padding: $padding-input;
  margin-bottom: $margin-bottom-input;
}

.form {
  &-create-client {
    min-width: rem(500);
    max-width: rem(600);

    .h3 {
      font-size: rem(22);
      font-weight: 500;
    }

    .text-small {
      color: $color-grey-secondary
    }
  }

  &-client-modal {

    .h2 {
      color: rgb(55, 53, 47);
      justify-content: center;
      font-size: rem(32);
      font-weight: 700;
    }

    & .modal-content {
      max-width: rem(600);
    }

    & .modal-dialog-centered {
      justify-content: center;
    }
  }

  .label {
    margin-bottom: $margin-bottom-label !important;
    padding: $padding-label !important;
  }
}

.select {
  background-color: $color-grey-light;
  border-radius: $border-radius-input;
  border: $main-border-input;
  padding: rem(4) rem(10);
  max-width: rem(150);
  display: flex;
  align-items: center;
  cursor: pointer;

  &-icon {
    display: inline-flex;
    justify-content: flex-end;
    width: 100%;
  }

  &-menu {
    position: absolute;
    z-index: 10000;
    background-color: $color-white;
    border-radius: $border-radius;
    border: $main-border-input;
    box-shadow: $box-shadow-main;
    margin-top: rem(-5);
    min-width: rem(150);

    &__container {
      padding: rem(12);
    }

    &__options {
      display: flex;
      flex-direction: column;

      & > li {
        cursor: pointer;

        &:hover {
          background-color: $color-grey-light;
          border-radius: $border-radius;
        }

        & > span {
          margin: rem(5) rem(5);
        }
      }
    }
  }
}

.custom-select {
  position: relative;
  min-width: auto;
  height: rem(41);
  background-color:  $color-grey-light;
  display: flex;
  align-items: center;
  border-radius: $border-radius-medium;

  &-options{
    position: absolute;
    display: none;
    color: inherit;
    background-color: $color-white;

    &.open {
      display: block;
      width: rem(280);
      padding: rem(5);
      border-radius: $border-radius-medium;
      border: $color-border;
      box-shadow: $box-shadow-main;
      transform: translate(rem(0), rem(59));
    }
  }

  &-content {
    width: 100%;
    height: 100%;
    padding: rem(10);
    display: flex;
    justify-content: flex-start;
    align-items: center;
  }

  &-option-item {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    font-size: rem(14);
    font-weight: 400;
    height: rem(30);
    padding: 0 0.75rem;

    &:hover {
      cursor: pointer;
      background-color: #F9DEC9;
      color: #D9720E;
    }
  }

  &-action {
    display: flex;
    justify-content: center;
    align-items: center;
    width: rem(41);
    height: 100%;
    border-left: rem(1) solid $color-border;

    &:hover {
      background-color: $neutral-quietest;
      cursor: pointer;
    }
  }
}

