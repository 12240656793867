.tag {
  border-radius: $border-radius-medium;
  padding: rem(3) rem(6);
  font-weight: 400;
  display: inline-flex;
  align-items: center;
  flex-shrink: 1;
  min-width: 0;
  max-width: 100%;
  height: 20px;
  font-size: rem(12);
  margin: 0;

  &-owner {
    color: $orange-orange;
    background-color: $orange-orangeLight2;
  }

  &-pending {
    color: $blue-blue;
    background-color: $blue-blueLight2;
  }

  &-payed {
    color: $purple;
    background-color: $purple-200;
  }

  &-validate {
    color: $green-green;
    background-color: $green-greenLight2;
  }

  &-unpaid {
    color: $red-red;
    background-color: $red-redLight2;
  }

  &-legal-notice, &--draft {
    color: $yellow-yellow;
    background-color: $yellow-yellowLight3;
  }

  &--inactive {
    color: $red-red;
    background-color: $red-redLight3;
  }

  &-accountable {
    color: $cyan-cyan;
    background-color: $blue-blueLight3;
  }

  &--active, &-accepted, &--publish {
    color: $green-greenDark1;
    background-color: $green-greenLight2;
  }
}