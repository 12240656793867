.table {
  border-collapse: collapse;
  background: white;
  width: 100%;
  margin: 0 auto;
  overflow: hidden;

  &__container {
    padding: $main-wrapper;
    margin-top: rem(10);
  }

  thead {
    tr {
      border: $border-main;

      th {
        font-size: rem(12);
        font-weight: 500;
        color: rgba(55, 53, 47, 0.8);
        opacity: 0.72;
        background-color: $color-table-thead;

        &.table-action {
          display: flex;
          justify-content: center;
          align-items: center;
          width: 100%;
        }
      }
    }
  }

  tbody {
    tr {
      border: $border-main;

      td {
        padding: rem(8) rem(8);
        font-size: rem(12);
        border-style: none solid solid none;

        &.table-action {
          text-align: center;
          cursor: pointer;
        }
      }

      .relative-content-cell {
        position: relative;
      }
    }


  }

  &-hover {
    tr:hover td {
      background: $neutral-lightGray1;
      cursor: pointer;
    }

    tr span.open-content {
      display: none;
      position: absolute;
      align-items: center;
      z-index: 99;
      transform: translateX(rem(30));

      & > svg {
        display: inline-block;
        align-items: center;
      }
    }

    tr:hover span.open-content {
      display: inline-block;
    }
  }

  .table-selected-row {
    td {
      background-color: $light;
    }
  }
}