.client-selecter {
  background-color: $color-white;
  border-radius: $border-radius-main;
  border: $border-main;
  position: absolute;
  transform: translate(rem(-2), rem(12));
  min-width: rem(280);
  box-shadow: $box-shadow-main;

  &__header {
    padding: rem(11) rem(14);

    .user-email {
      font-size: rem(11);
      line-height: rem(16);
      font-weight: 500;
      color: rgba(55, 53, 47, 0.65);
    }

    .current-client {
      font-size: rem(14);
      line-height: rem(20);
      font-weight: 400;
      display: flex;
      align-items: center;
      margin: rem(8) 0;

      .client-logo {
        height: rem(32);
        width: rem(32);
        background-color: #0177D3;
        margin-right: rem(8);
      }

      .client-name {
        display: flex;
        flex-direction: column;

        & span {
          font-size: rem(12);color: #5D5C588D;
        }
      }
    }
  }

  &__footer {
    background-color: #F8F8F8;
    padding: rem(10) rem(4);
    border-top: rem(1) solid #E6E6E6;
    border-radius: 0 0 rem(6) rem(6);

    & li {
      font-size: rem(12);
      font-weight: 500;
      height: rem(28);
      padding: 0 rem(12);
      display: flex;
      align-items: center;

      &:hover {
        cursor: pointer;
        background-color: $color-grey-hover;
        border-radius: $border-radius-main;
      }
    }
    & a {
      padding: rem(5);
    }
  }
}