.icon {

  &--orange {
    color: $color-orange;
  }
}

.icon-bg{
  background-color: rgba(217, 114, 14, 0.2);
  height: rem(20);
  width: rem(20);
  border-radius: rem(3);
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;

  .icon {
    display: flex;
    align-items: center;
    justify-content: center;
    width: rem(15);
    height: rem(15);
    margin: 0;
  }
}