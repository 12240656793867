.btn {
  border-width: 0;
  border-style: none;
  cursor: pointer;
  display: flex;
  align-items: center;
  font-size: rem(13);
  
  &:hover {
    cursor: pointer;
  }

  &-info {
    border-color: transparent;
    padding: rem(2) rem(8);

    &--cyan {
      background-color: $color-cyan;
      color: white;
      border-radius: 3px;
      height: rem(35);
    }
  }

  &-primary {
    border-color: transparent;
    padding: rem(2) rem(8);

    &--blue {
      background-color: $color-blue;
      color: white;
      border-radius: 3px;
      height: rem(35);
    }
  }

  &-default {
    background-color: transparent;

    &--red {
      color: $red-red;
      &:hover {
        color: $red-red;
        cursor: pointer;
      }
    }

    &--blue {
      color: $color-blue;

      &:hover {
        color: $color-blue;
        cursor: pointer;
      }
    }

    &--cyan {
      color: $color-cyan;
    }

    &:hover {
      cursor: pointer;
    }
  }

  & > svg {
    margin-right: rem(4);
  }

  &.underline-hover:hover {
    text-decoration: underline;
  }
}