.flash-message {
  position: fixed;
  bottom: rem(20);
  right: rem(20);
  color: white;
  padding: rem(12) rem(15);
  border-radius: $border-radius-medium;
  box-shadow: $box-shadow-main;
  border: $border-main;
  background-color: $color-white;
  z-index: 10000;
  transition: all 0.3s ease-in-out;
  font-size: rem(14);
  display: flex;
  align-items: center;

  &.fade-out {
    opacity: 0;
    transform: translateX(rem(100)) translateY(rem(20));
  }

  &-icon {
    background-color: $green-greenLight1;
    height: rem(30);
    width: rem(30);
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: rem(10);
    border-radius: $border-radius;

    & > svg {
      width: rem(20);
    }
  }

  &.success {
    color: $neutral-black;
    background-color: $green-greenLight3;
    border: rem(1) solid $green-greenLight1;
  }

  &.error {
    color: $neutral-black;
    background-color: $red-redLight3;
    border: rem(1) solid $red-redLight1;
  }

  &.warning {
    color: $neutral-black;
    background-color: $yellow-yellowLight3;
    border: rem(1) solid $yellow-yellowLight1;
  }
}


