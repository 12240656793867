.dragging-container {
  background-color: $color-white-background;
  border: $border-main;
  padding: rem(10);
  border-radius: rem(4);
}

.dragging {
  .badge {
    background-color: $color-green;
    color: $white;
  }
}

.container-annex {
  display: flex;
  flex-wrap: wrap;
  min-height: rem(250);
}